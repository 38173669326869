// deps
import { useCallback, useMemo } from "react";
import { useRouter } from "next/router";

// components
import Pagination from "../../components/Pagination";
import parseQuery from "@splitfire-agency/raiden-library/dist/libraries/utils/parseQuery";

// contexts
import { useSearch } from ".";
import { usePreferences } from "../Preferences";

function SearchPagination({ PaginationComponent = Pagination }) {
  const {
    submittedFields,
    response,
    paginationUrl,
    form,
    displayedPerPages,
    onSubmit,
    routerSyncUrl,
  } = useSearch();

  const router = useRouter();

  /**
   /**
   * Permet d'exclure des valeurs de l'URL du router.
   * on utilise cette méthode en interne à la place de router.query pour éviter de récupérer des valeurs non souhaitées
   * par exemple sur une url du type customers/170033/view/services?user_id=170033&sort=created_desc&per_page=100
   * si on utiliser router.query on récupère user_id, sort, per_page, mais également id (170033 de  customers/170033/view/services) car c'est une valeur dynamique injectée dans le router
   * en utilisant cette méthode on peut exclure id de la récupération des valeurs
   * @returns {object}
   */
  const getRouterQuery = useCallback(
    function () {
      return parseQuery(router.asPath.split("?")[1] ?? "");
    },
    [router.asPath],
  );

  const decoratedPaginationUrl = useMemo(
    function () {
      if (paginationUrl) {
        return function ({ perPage, page }) {
          return paginationUrl({
            fields: {
              ...(routerSyncUrl && getRouterQuery()),
              ...submittedFields,
              per_page: perPage,
              page,
              total: undefined,
            },
          });
        };
      }
    },
    [submittedFields, paginationUrl, routerSyncUrl, getRouterQuery],
  );

  const { setPaginationPerPage } = usePreferences();

  const responseTotal = response.data?.meta?.total ?? 0;
  const responsePerPage = response.data?.meta?.per_page;
  const responsePage = response.data?.meta?.current_page;

  return (
    <>
      {responseTotal > 0 && (
        <PaginationComponent
          page={responsePage}
          perPage={responsePerPage}
          total={responseTotal}
          href={decoratedPaginationUrl}
          displayedPerPages={displayedPerPages}
          onChangePage={function (event, { page }) {
            form.setValue("page", page);
            onSubmit();
          }}
          onChangePerPage={function (event, { perPage }) {
            form.setValue("page", 1);
            form.setValue("per_page", perPage);
            setPaginationPerPage(perPage);
            onSubmit();
          }}
        />
      )}
    </>
  );
}

export default SearchPagination;
