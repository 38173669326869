// deps
import { Box, Button } from "@chakra-ui/react";
import { isSameMonth } from "@internationalized/date";
import { useCalendarCell } from "@react-aria/calendar";
import { useCallback, useMemo, useRef } from "react";

/**
 * @typedef Props
 * @property {import("@react-stately/calendar").CalendarState | import("@react-stately/calendar").RangeCalendarState} state
 * @property {import("@internationalized/date").CalendarDate} date
 * @property {object} currentMonth
 * @property {import("react").Dispatch<import("react").SetStateAction<any>>} [setOpen]
 * @property {boolean} [shouldCloseOnDaySelect]
 * @property {(params: { ymd: string }) => boolean} [isDisabled]
 */
/**
 * @param {Props} props
 * @returns {import("react").FunctionComponentElement<Props>}
 */
export function CalendarCell({
  state,
  date,
  currentMonth,
  setOpen,
  shouldCloseOnDaySelect,
  isDisabled,
}) {
  let ref = useRef(null);

  const ymd = useMemo(() => {
    return date.toString();
  }, [date]);

  const isUnavailable = useMemo(() => {
    return isDisabled?.({ ymd });
  }, [isDisabled, ymd]);

  let { cellProps, buttonProps, isSelected, isInvalid, formattedDate } =
    useCalendarCell({ date, isDisabled: isUnavailable }, state, ref);

  let isOutsideMonth = !isSameMonth(currentMonth, date);

  const onCellClick = useCallback(() => {
    if (shouldCloseOnDaySelect) {
      setOpen?.(false);
    }
  }, [setOpen, shouldCloseOnDaySelect]);

  return (
    <Box as="td" {...cellProps} textAlign="center">
      <Button
        {...buttonProps}
        ref={ref}
        hidden={isOutsideMonth}
        size="sm"
        colorScheme={isInvalid ? "red" : "blue"}
        variant={isSelected ? "solid" : "ghost"}
        onClick={onCellClick}
        transition="none"
        minW="42px"
        isDisabled={isUnavailable}>
        {formattedDate}
      </Button>
    </Box>
  );
}

export default CalendarCell;
